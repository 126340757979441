.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-button {
    background-color: rgba(214, 144, 4, 0.933);
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .scroll-button svg {
    color: #fff;
    width: 32px;
    height: 32px;
  }
  
  .scroll-to-top .scroll-button:hover {
    background-color: rgba(166, 111, 1, 0.933);
  }
  