.login-form-container {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form-heading {
    text-align: center;
    margin-bottom: 20px;
    color:white;
    font-size: 24px;
    font-weight: bold;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: white;
  }
  
  .login-form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .login-form-submit {
    padding: 10px;
    background-color: rgba(214, 144, 4, 0.933);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .login-form-submit:hover {
    background-color: rgba(166, 111, 1, 0.933);
  }
  
  .login-bt {
    display: flex;
    justify-content: center;
    align-items: center;
  }
/* Add these styles to your login.css */
.password-input-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.password-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
  margin-left: -30px;
}

.forgot-password {
  margin-top: 10px;
  text-align: right;
}

.forgot-password span {
  color: #0056b3;
  cursor: pointer;
  text-decoration: underline;
}

.forgot-password span:hover {
  color: #fff;
}
