/* loadingLine.css */
.loading-line-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px; /* Adjust the height of the line */
  background: transparent;
  z-index: 1000;
  overflow: hidden;
}

.loading-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #f0a500, #d17e00); /* Adjust colors */
  animation: loadingLineMove 2s linear infinite;
}

@keyframes loadingLineMove {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
