.book-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.book-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  transition: transform 0.5s ease-in-out;
  padding: 20px;
}

.book-page.enter-from-right {
  transform: translateX(100%);
}

.book-page.enter-from-left {
  transform: translateX(-100%);
}

.book-page.enter-active {
  transform: translateX(0);
}

.book-page.exit-to-left {
  transform: translateX(-100%);
}

.book-page.exit-to-right {
  transform: translateX(100%);
}

.book-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.book-info {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #59814B;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.book-title {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.bt-h3-bs {
  display: flex;
  justify-content: center;
}

.book-description {
  font-size: 17px;
  line-height: 1.5;
  color: #ffffff;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-height: 300px; /* Ensures that the description doesn't exceed a certain height */
}

.book-navigation {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.book-nav-button {
  padding: 10px 20px;
  background-color: #59814B;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.book-nav-button:hover {
  background-color: #3f5b35;
}

.book-nav-button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .book-container {
    padding: 15px;
  }

  .book-page {
    padding: 15px;
  }

  .book-image {
    max-width: 100%;
    margin-bottom: 15px;
  }

  .book-info {
    padding: 15px;
  }

  .book-title {
    font-size: 22px;
    margin-bottom: 8px;
  }

  .book-description {
    font-size: 14px;
  }

  .book-nav-button {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .book-container {
    padding: 10px;
  }

  .book-page {
    padding: 10px;
  }

  .book-image {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .book-info {
    padding: 10px;
  }

  .book-title {
    font-size: 20px;
    margin-bottom: 6px;
  }

  .book-description {
    font-size: 12px;
  }

  .book-nav-button {
    padding: 6px 12px;
  }
}
