/* Container Styles */
.register-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/images/hero2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Form Selection Button Styles */
.form-selection-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-selection-container button {
  background-color: rgba(214, 144, 4, 0.933);
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.form-selection-container button.active {
  background-color: rgba(214, 144, 4, 0.933);
}

.form-selection-container button:not(.active):hover {
  background-color: rgba(144, 96, 1, 0.933);
}

/* Form Wrapper Styles */
.form-wrapper {
  background: linear-gradient(rgba(90, 88, 88, 0.7), rgba(66, 65, 65, 0.7));
  padding: 40px;
  border-radius: 10px;
  min-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Registration and Login Form Container Styles */
.form-container {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

/* Button Styles */
.form-submit-btn {
  background-color: rgba(214, 144, 4, 0.933);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.form-submit-btn:hover {
  background-color: rgba(214, 144, 4, 0.933);
}

.button-container {
  text-align: center;
}


/* Back button styling */
.back-button {
  position: fixed;
  top: 30px;
  left: 30px;
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  transition: color 0.3s ease;
}

.back-button svg {
  width: 40px;
  height: 40px;
}

.back-button:hover {
  color: rgba(166, 111, 1, 0.933);
}

@media (max-width: 768px) {
  .register-page-container {
    padding: 20px;
    height: 100%;    
  }
}