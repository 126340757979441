/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General styles */
.admin-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fdeacb;
  color: #492300;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.div-rig {
  display: flex;
  align-items: center;
}

.logout-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #38512F;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-div:hover {
  background-color: #555;
}

.admin-header .logo {
  width: 50px; /* Adjust as per your logo size */
  height: auto;
  border-radius: 10px;
  margin-right: 10px;
}

.admin-header h1 {
  margin: 0;
}

.admin-content {
  display: flex;
}

.main-content {
  flex: 1;
  padding: 20px;
}

/* Sidebar styles */
.sidebar {
  width: 250px;
  background-color: #38512F;
  color: #fff;
  padding: 20px;
  height: 100%;
  min-height: 83.5vh;
  overflow-y: auto;
}

.sidebar ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
  cursor: pointer;
}

.sidebar ul li:hover {
  background-color: #61de65;
  border-radius: 30px;
}

/* Dashboard styles */
.admin-profile-container {
  padding: 20px;
  background-color: #38512F;
  border-radius: 30px;
  min-height: 100vh;
  color: #fff;
}

.admin-profile-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.admin-profile-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.admin-profile-section {
  background-color: #fff1dc;
  color: #38512F;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-details {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.admin-avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.admin-avatar img {
  max-width: 180px;
  max-height: 180px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.admin-info {
  flex: 1;
}

.admin-info-table {
  width: 100%;
}

.admin-info-table td {
  padding: 10px;
}

.admin-info-table tr {
  border-bottom: 1px solid #444;
}

.admin-edit-button {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-edit-button:hover {
  background-color: #45a049;
}

.admin-profile-form {
  display: flex;
  flex-direction: column;
}

.admin-profile-form-group {
  margin-bottom: 15px;
}

.admin-profile-form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.admin-profile-form-group input {
  padding: 8px;
  border-radius: 4px;
  border: none;
  outline: none;
  width: 100%;
  background-color: #38512F;
  color: #fff;
}

.admin-profile-form-actions {
  display: flex;
  gap: 10px;
}

.admin-profile-submit-button,
.admin-profile-cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-profile-submit-button {
  background-color: #4CAF50;
  color: #fff;
}

.admin-profile-cancel-button {
  background-color: #ff4d4d;
  color: #fff;
}

.admin-profile-submit-button:hover,
.admin-profile-cancel-button:hover {
  background-color: #45a049;
}



/* Admins Component */
.admins {
  padding: 20px;
}

.admins h2 {
  margin-bottom: 20px;
  color: #333;
}

.admins button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admins button:hover {
  background-color: #45a049;
}

/* Admin Card */
.admin-card {
  display: flex;
  align-items: center;
  background-color: #fdeacb;
  color: #38512F;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.admin-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.admin-info {
  flex: 1;
}

.admin-actions button {
  margin-right: 10px;
  padding: 8px 16px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-actions button:hover {
  background-color: #e53935;
}

/* Admin Form */
.admin-form {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fdeacb;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-form h2 {
  margin-bottom: 20px;
  color: #38512F;
}

.admin-form label {
  display: block;
  color: #38512F;
  margin-bottom: 10px;
}

.admin-form input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.admin-form .form-buttons {
  display: flex;
  justify-content: flex-end;
}

.admin-form button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-form button:hover {
  background-color: #45a049;
}


/* Users Component CSS */
.ad-us-filters {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}
.ad-us-title{
  color: #38512F;
  margin-bottom: 10px;
}

.ad-us-filter-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ad-us-filter-button.active {
  background-color: #45a049;
}

.ad-us-filter-button:hover {
  background-color: #3e8e41;
}

.ad-us-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.ad-us-table th, .ad-us-table td {
  padding: 12px;
  border: 1px solid #444;
  text-align: left;
}

.ad-us-table th {
  background-color: #38512F;
  color: #fff;
}

.ad-us-table td {
  background-color: #fdeacb;
  color: #38512F;
}
.td-image-us{
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-us-table-img {
  max-width: 50px;
  height: 50px;
  align-self: center;
  border-radius: 50%;
}

.ad-us-eye-icon {
  margin-left: 10px;
  cursor: pointer;
  color: #4CAF50;
}

.ad-us-approve-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ad-us-approve-button.approve {
  background-color: #4CAF50;
  color: #fff;
}

.ad-us-approve-button.disapprove {
  background-color: #ff4d4d;
  color: #fff;
}

.ad-us-approve-button:hover {
  opacity: 0.9;
}
.ad-us-popup {
  background: #333;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Change to flex-start for better alignment */
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  width: 100%;
  color: #fff;
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  max-height: 80vh; /* Limit the height to 80% of the viewport */
}

.ad-us-popup p {
  word-wrap: break-word; /* Ensure long words break onto the next line */
  word-break: break-word; /* Ensure any word break to the next line */
  margin-bottom: 10px; /* Space between paragraphs */
  white-space: pre-line; /* Preserve line breaks in the text */
  text-align: left; /* Ensure text aligns to the left */
}

.ad-us-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-us-close-button {
  background-color: #ff4d4d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  align-self: center; /* Center the close button */
}

.ad-us-close-button:hover {
  background-color: #d63d3d;
}

/* NGOs Component */
.ad-ng-ngos {
  padding: 10px;
}

.ad-ng-title {
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.ad-ng-add-button {
  margin-bottom: 15px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  margin: 15px auto;
}

.ad-ng-add-button:hover {
  background-color: #45a049;
}

.ad-ng-ngo-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ad-ng-ngo-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #38512F;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  color: #fff;
}
.ad-ngo-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden; /* Hide any overflow */
  margin-right: 20px;
  display: flex; /* Center the image inside the container */
  align-items: center;
  justify-content: center;
}

.ad-ng-ngo-picture {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}
.ad-ng-ngo-info {
  flex: 1;
}

.ad-ng-ngo-actions {
  margin-left: 15px;
}

.ad-ng-edit-button {
  padding: 8px 16px;
  background-color: #FFC107;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 8px;
}

.ad-ng-edit-button:hover {
  background-color: #FFA000;
}

.ad-ng-delete-button {
  padding: 8px 16px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ad-ng-delete-button:hover {
  background-color: #e53935;
}

.ad-ng-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.ad-ng-modal-content {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.ad-ng-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ad-ng-modal-header h2 {
  margin: 0;
  color: #333;
}

.ad-ng-modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.ad-ng-ngo-form {
  display: flex;
  flex-direction: column;
}

.ad-ng-ngo-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  color: #333;
}

.ad-ng-ngo-form input,
.ad-ng-ngo-form textarea {
  width: 100%;
  padding: 6px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.ad-ng-form-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.ad-ng-submit-button {
  padding: 8px 15px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 8px;
}

.ad-ng-submit-button:hover {
  background-color: #45a049;
}

.ad-ng-cancel-button {
  padding: 8px 15px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ad-ng-cancel-button:hover {
  background-color: #e53935;
}
/* Internships */

.ad-it-internships {
  padding: 20px;
  background-color: #38512F;
  border-radius: 8px;
}

.ad-it-title {
  margin-bottom: 20px;
  color: #ffffff;
}

.ad-it-filter {
  margin-bottom: 20px;
}

.ad-it-filter label {
  margin-right: 10px;
  color: #ffffff;
}

.ad-it-filter input {
  padding: 5px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #ffffff;
  color: #38512F;
}

.ad-it-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.ad-it-table th,
.ad-it-table td {
  border: 1px solid #444;
  padding: 8px;
  text-align: left;
}

.ad-it-table th {
  background-color: #fdeacb;
  color: #38512F;
}

.ad-it-table tr:nth-child(even) {
  background-color: #fef9f0;
  color: #38512F;
}

.ad-it-table tr:nth-child(odd) {
  background-color: #ededed;
  color: #38512F;
}

.ad-it-table tr:hover {
  background-color: #555;
  cursor: pointer;
}

.ad-it-table tr:hover td {
  color: #f0a500;
}
.ad-us-ac-bx{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Galleries Component */
/* Galleries Component */
.ad-ga-galleries {
  padding: 20px;
  background-color: #fdeacb;
  border-radius: 30px;
  color: #fff;
}

.ad-ga-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #38512F;
}

.ad-ga-form {
  margin-bottom: 30px;
}

.ad-ga-label {
  display: block;
  margin-bottom: 10px;
  color: #38512F;
}

.ad-ga-input, .ad-ga-textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #fff;
  color: #38512F;
}

.ad-ga-button {
  padding: 10px 20px;
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.ad-ga-button:hover {
  background-color: #3e8e41;
}

.ad-ga-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.ad-ga-card {
  background-color: #38512F;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 15px;
  width: calc(33.333% - 20px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ad-ga-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.ad-ga-card-info {
  margin-top: 10px;
}

.ad-ga-card-title {
  font-size: 18px;
  margin-bottom: 40px;
  color: #ffffff;
}

.ad-ga-description {
  font-size: 14px;
  margin-bottom: 10px;
}

.ad-ga-read-more {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  text-decoration: underline;
}

.ad-ga-read-more:hover {
  color: #cc8400;
}

.ad-ga-card-buttons {
  display: flex;
  justify-content: space-between;
}

.ad-ga-modal, .ad-ga-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ad-ga-modal-content, .ad-ga-popup-content {
  background-color: #38512F;
  padding: 20px;
  border-radius: 4px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.ad-ga-modal-content h2, .ad-ga-popup-content h2 {
  color: #fff;
}

.ad-ga-popup-content p {
  color: #fff;
  text-align: left;
}
.admins-bx{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



/* Additional styles for AdminForm and AdminCard */
.admin-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
  cursor: pointer;
}

.admin-avatar {
  margin-bottom: 10px;
}



.admin-info {
  text-align: center;
}

.admin-actions {
  margin-top: 10px;
}

.admins button {
  margin-bottom: 20px;
}



/* Queries */

.ad-qr-queries {
  padding: 20px;
  background-color: #fdeacb;
  border-radius: 30px;
  color: white;
  min-height: 100vh;
}

.ad-qr-title {
  margin-bottom: 20px;
  color: #38512F;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.ad-qr-table {
  width: 100%;
  border-collapse: collapse;
}

.ad-qr-table th, .ad-qr-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: top;
}
.ad-qr-table td{
  color: #38512F;
}
.ad-qr-table th {
  background-color: #38512F;
}

.ad-qr-table tr:nth-child(even) {
  background-color: #fdf3e3;
}

.ad-qr-table tr:hover {
  background-color: #45a049;
  color: white !important;
  cursor: pointer;
}

.ad-qr-message {
  max-height: 100px;
  overflow: hidden;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ad-qr-message.expanded {
  max-height: none;
}

.ad-qr-read-more {
  background: none;
  border: none;
  color: #4CAF50;
  cursor: pointer;
  display: block;
  margin-top: 5px;
}

.ad-qr-delete-button {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ad-qr-delete-button:hover {
  background-color: #ff1a1a;
}


/* Change Password */
.ad-cp-container {
  padding: 20px;
  background-color: #fdeacb;
  border-radius: 30px;
  color: #38512F;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ad-cp-ps{
  margin-bottom: 20px;
  color: #38512F;
}

.ad-cp-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #38512F;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ad-cp-form label {
  margin-bottom: 10px;
  font-weight: bold;
}

.ad-cp-form input {
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}

.ad-cp-form button {
  padding: 10px;
  background-color: #59814B;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.ad-cp-form button:hover {
  background-color: #3f5b35;
  transform: scale(1.05);
}


