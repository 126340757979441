
/* homePage.css */

.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  color: #FEFEFE;
  text-align: center;
  font-size: 2.5rem;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../assets/images/hero.jpg') center/cover no-repeat;
}

.hero-content {
  max-width: 800px;
  margin: 0 20px;
  padding: 30px 20px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.hero-title {
  font-size: 2.81rem;
  font-weight:lighter;
  margin-bottom: 20px;
  color: #FFFFFF;

}

.hero-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.5;
}

.hero-button {
  /* background: linear-gradient(90deg, #e6ce78 0%, #e9c957 100%); */
  background-color: #59814B;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
}

.hero-button:hover {
  /* background: linear-gradient(90deg, #d17e00 0%, #f0a500 100%); */
  background-color: #3f5b35;
  transform: scale(1.05);
  transition: transform 0.3s;
}

.hero-link {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: inherit;
}

/* Responsive design */
@media (max-width: 768px) {
  .hero-section {
    height: auto;
    padding: 20px 0;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-description {
    font-size: 1rem;
  }

  .hero-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .hero-description {
    font-size: 0.9rem;
  }

  .hero-button {
    padding: 8px 15px;
    font-size: 0.8rem;
  }
}


/* goalsSection.css */

.goals-section {
  padding: 60px 20px;
  /* background-color: #F7F3E9; */
  background-color: #E1D9CD;
  text-align: center;
}

.goals-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  /* color: #492300; */
  color:#3f5b35;
}

.goals-description {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
  max-width: 800px;
  margin: 0 auto 40px;
}

.goals-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.goal-item {
  background: #fff;
  padding: 35px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 10px;
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  transition: transform 0.3s, box-shadow 0.3s;
}

.goal-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.goal-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
  /* color: #492300; */
  color:#3f5b35;
}

.goal-text {
  font-size: 1.12rem;
  color: #333;
}

/* Responsive design */
@media (max-width: 768px) {
  .goal-item {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .goal-item {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .goals-title {
    font-size: 2rem;
  }

  .goals-description {
    font-size: 1rem;
  }

  .goal-title {
    font-size: 1.3rem;
  }

  .goal-text {
    font-size: 0.9rem;
  }
}
/* Opportunities Section */
.opportunities-section {
  padding: 60px 20px;
  /* background-color: #faf9f8; */
  background-color: #38512F;
  text-align: center;
}


.opp-class{
  text-decoration: none;
  
}

.opportunities-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
}
.opportunity-link-wrapper{
  max-width: calc(50% - 20px);
    color:#333 ;
    text-decoration: none;
    padding: 0rem;
    text-transform: none !important;
    font-weight: normal;

  }
.opportunities-description {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #fff;
  max-width: 800px;
  margin: 0 auto 40px;
}

.opportunities-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.opportunity-item {
  background: #f4f4f4;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 10px;
  flex: 1 1 calc(50% - 20px);
  width: 100%;
  height: 100%;
}
.hm-op-tst{
  margin-top: 20px;
}

.opportunity-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.opportunity-title {
  font-size: 1.9rem;
  font-weight: bold;
  color: #3f5b35;
}

.opportunity-description {
  font-size: 1.3rem;
  color: #333;
}

/* Responsive design */
@media (max-width: 768px) {
  .opportunities-list {
    flex-direction: column;
    align-items: center;
  }

  .opportunity-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .opportunity-link-wrapper {
    flex: 1 1 100%;
    max-width: 100%;
  }
}



/* About Us Section */
.about {
  padding: 60px 20px;
  text-align: center;
  background-color: #E1D9CD;

}

.about h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #3f5b35;
}

.about p {
  font-size: 1.4rem;
  line-height: 1.5;
  color: #333;
  max-width: 800px;
  margin: 0 auto 40px;
}

/* homePage.css */

/* Our Team Section */
.team-section {
  padding: 60px 20px;
  background-color: #38512F;
  text-align: center;
}

.team-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
}

.team-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.team-members-container {
  display: flex;
  transition: transform 0.5s ease;
}

.team-member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 10px;
  flex: 1 0 auto;
  min-width: 300px;
}

.team-member:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.team-member-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 15px;
}

.team-member-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #492300;
  font-weight: bold;
}

.team-member-role {
  font-size: 1rem;
  color: #333;
}

.team-prev-button,
.team-next-button {
  background-color: #fff;
  color: #3f5b35;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5rem;
  margin: 0 10px;
  transition: background-color 0.3s;
}

.team-prev-button:hover,
.team-next-button:hover {
  background-color: #74a960;
  color: #fff;
}

.swipe-text {
  margin-top: 10px;
  color: #fff;
  display: none;
}

/* Responsive Styles */
@media (min-width: 1024px) {
  .team-member {
    max-width: calc(33.333% - 20px); /* Display 3 team members per row */
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .team-member {
    max-width: calc(50% - 20px); /* Display 2 team members per row */
  }
}

@media (max-width: 767px) {
  .team-prev-button,
  .team-next-button {
    display: none;
  }

  .swipe-text {
    display: block;
  }
}

/* Our Partners Section */
.partner-section {
  padding: 60px 20px;
  background-color: #38512F;
  text-align: center;
}

.partner-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
}

.partner-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.partner-members-container {
  display: flex;
  transition: transform 0.5s ease;
}

.partner-member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 10px;
  flex: 1 0 auto;
  min-width: 300px;
}

.partner-member:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.partner-member-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 15px;
}

.partner-member-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #492300;
  font-weight: bold;
}

.partner-member-role {
  font-size: 1rem;
  color: #333;
}

.partner-prev-button,
.partner-next-button {
  background-color: #fff;
  color: #3f5b35;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5rem;
  margin: 0 10px;
  transition: background-color 0.3s;
}

.partner-prev-button:hover,
.partner-next-button:hover {
  background-color: #74a960;
  color: #fff;
}

.swipe-text {
  margin-top: 10px;
  color: #fff;
  display: none;
}

/* Responsive Styles */
@media (min-width: 1024px) {
  .partner-member {
    max-width: calc(33.333% - 20px); /* Display 3 partners per row */
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .partner-member {
    max-width: calc(50% - 20px); /* Display 2 partners per row */
  }
}

@media (max-width: 767px) {
  .partner-prev-button,
  .partner-next-button {
    display: none;
  }

  .swipe-text {
    display: block;
  }
}


/* Hide the scrollbar */
body::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

/* Track */
body::-webkit-scrollbar-track {
  background: #E1D9CD; /* color of the track */
  border-radius: 10px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #38512F; /* color of the thumb */

  border-radius: 10px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #3f5b35; /* color of the thumb when hovered */
}
