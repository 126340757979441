/* footer.css */

.footer-section {
    /* background: linear-gradient(90deg, #B59626 0%, #e7d287 100%); */
    background-color: #fdeacb;
    color: #492300;
    padding: 40px 20px;
    font-family: 'Arial, sans-serif';
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-logo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.footer-logo img {
    width: 100px;
    margin-bottom: 10px;
    border-radius: 30px;
}

.footer-brand {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
}

.footer-links {
    flex: 4;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-column {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.footer-heading {
    font-size: 1.1rem;
    margin-bottom: 10px;
    text-align: center;
    color: #492300;
}

.footer-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-list-item {
    margin-bottom: 8px;
    font-size: 1.1rem;
}

.footer-app-link {
    color: #fff;
    text-decoration: none;
}

.footer-app-link img {
    width: 100px;
    margin: 5px;
}

.footer-app-link:hover {
    text-decoration: underline;
}

/* Responsive design */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-links {
        width: 100%;
    }

    .footer-column {
        flex: 1 1 100%;
        max-width: 100%;
        align-items: center;
    }
}
.footer-link {
    color: #492300;
    font-weight: 100;
    font-size: 1rem;
    text-decoration: none;
    padding: 0%;
}

.footer-link:hover {
    text-decoration: underline;
}
.footer-para{
    font-size: 1.1rem;
    color: #492300;
    font-weight: 100;
    text-align: center;
}
