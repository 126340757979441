*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;  
}

body {
  font-family: Arial, sans-serif;
  background-color: #E1D9CD;
  color: #fff;
  margin: 0;
  padding: 0;
}

.ngos-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.ngos-page h1 {
  font-size: 2.5rem;
  color: #3f5b35;
  margin-bottom: 20px;
}

.ngos-page input {
  padding: 10px;
  background-color: #fff;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  font-size: 1rem;
}

.internship-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.internship-item {
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #555;
  border-radius: 8px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  background-color: #FFFFFF;
  color: #333;
  display: flex;
  align-items: center;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.internship-item:hover {
  background-color: #eae9e9;
  transform: scale(1.02);
}

.internship-item-image {
  width: 150px; /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  overflow: hidden;
  border-radius: 8px;
  margin-right: 15px; /* Add some space between the image and the text */
  flex-shrink: 0; /* Prevent the image from shrinking */
  
}

.internship-item-image img {
  width: 100%;
  height: 100%;
  object-fit:contain fill cover ;

}

.internship-item-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1; /* Allow the text to take the remaining space */
}

.internship-item-info h2 {
  font-size: 1.5rem;
  color: #3f5b35;
  font-weight: bold;
}
.ng-p-str{
  color: #3f5b35;
  font-weight: bold;
}

.internship-item-info p {
  margin: 5px 0;
}

.slide-in-left {
  animation: slideInLeft 0.5s ease-out forwards;
}

.slide-in-right {
  animation: slideInRight 0.5s ease-out forwards;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Media Queries */

@media (max-width: 768px) {
  .ngos-page {
    padding: 10px;
  }

  .internship-item {
    padding: 10px;
    font-size: 0.9rem;
    flex-direction: column; /* Stack image and text vertically on smaller screens */
    align-items: flex-start; /* Align items to the start */
  }

  .internship-item-image {
    margin-right: 0; /* Remove right margin */
    margin-bottom: 10px; /* Add bottom margin */
    width: 100%; /* Make the image full width */
    height: auto; /* Adjust height to maintain aspect ratio */
  }
}

@media (max-width: 480px) {
  .ngos-page {
    padding: 5px;
  }

  .ngos-page h1 {
    font-size: 2rem;
  }

  .internship-item {
    padding: 8px;
    font-size: 0.8rem;
  }

  .internship-item-image {
    height: 150px;
  }

  .internship-item-info h2 {
    font-size: 1.2rem;
  }
}
