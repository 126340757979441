.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.popup-overlay.fade-in {
  opacity: 1;
}

.popup-overlay.fade-out {
  opacity: 0;
}

.popup {
  background: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 400px;
  width: 100%;
  color: #fff;
  text-align: center;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateY(-20px);
  opacity: 0;
}

.popup.slide-in {
  transform: translateY(0);
  opacity: 1;
}

.popup.slide-out {
  transform: translateY(-20px);
  opacity: 0;
}

.popup-icon {
  margin-bottom: 20px;
}

.popup-icon svg {
  fill: #ff6b6b;
}

.popup p {
  margin-bottom: 20px;
}

.popup-close-button {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-close-button:hover {
  background-color: #45a049;
}
