/* register.css */

 
.registration-form-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.registration-form-heading {
  text-align: center;
  margin-bottom: 20px;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.registration-form {
  width: 100%;
}

.registration-form-group {
  margin-bottom: 15px;
}

.registration-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #fff;
}

.registration-form-group input,
.registration-form-group select,
.registration-form-group textarea {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.registration-form-group textarea {
  resize: vertical;
}

.registration-form-submit {
  padding: 10px;
  background-color: rgba(214, 144, 4, 0.933);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.registration-form-submit:hover {
  background-color: rgba(166, 111, 1, 0.933);
}

/* Custom file button styling */
.custom-file-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: rgba(214, 144, 4, 0.933);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.custom-file-button:hover {
  background-color: rgba(166, 111, 1, 0.933);
}

/* Media Queries */

@media (max-width: 768px) {
  .registration-form-container {
    padding: 10px;
  }

  .registration-form-heading {
    font-size: 20px;
  }

  .registration-form-group input,
  .registration-form-group select,
  .registration-form-group textarea {
    font-size: 14px;
    width: 90%;
  }

  .register-btn {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .registration-form-container {
    margin: 10px;
  }

  .registration-form-heading {
    font-size: 18px;
  }

  .registration-form-group input,
  .registration-form-group select,
  .registration-form-group textarea {
    font-size: 12px;
    width: 90%;
  }

  .registration-form-submit {
    align-self: center;
  }

  .register-btn {
    display: flex;
    justify-content: center;
  }
}

.register-btn {
  display: flex;
  flex-direction: column ;
  align-items: center;
  justify-content: center;
}
.file-name{
  color: white;
  margin-top: 10px;
}
.rg-pay{
  text-align: center;
}

.reg-pay{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.registration-volunteer{
  background-color: rgb(211, 138, 4);
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
}