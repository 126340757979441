
.container-fluid {
  padding: 0;
  background-color: #fdeacb;
  font-weight:600;
  text-align:center !important;
  color: white;
  display: flex;
  height: 15vh;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
.nav-logo{
  display: flex;
  align-items: center;
  font-weight: 600;
}
.nav-logo img{
  width: 80px;
  height: 80px;
  margin: 0 auto;
  display: block;
  padding: 1em;
  border-radius: 60px;
  cursor: pointer;
}

.nav-logo img:hover{
  transform: scale(1.2);
  transition-duration: .4s;
}

.hoverable{
  display:inline-block;
  backface-visibility: hidden;
  vertical-align: middle;
  position:relative;
  box-shadow: 0 0 1px rgba(0,0,0,0);
   transition-duration: .3s;
  transition-property:transform;
}

.ul-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
ul{
  padding:0;
  margin:0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
li{
  display:inline-block;

}
a{
  text-decoration: none;
  color: #492300;
  font-size: 1.1em;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  padding: 1em;
  position: relative;
  transition: .5s color ease-in-out;

}
.hoverable:before{
  position:absolute;
  pointer-events: none;
  z-index:-1;
  content: '';
  top: 100%;
  left: 5%;
  height:10px;
  width:90%;
  opacity:0;
  background: -webkit-radial-gradient(center, ellipse, rgba(235, 231, 224, 0.35) 0%, rgba(237, 229, 218, 0) 80%);
  background: radial-gradient(ellipse at center, #4923008e 0%, rgba(223, 214, 197, 0) 80%);
  /* W3C */
  transition-duration: 0.3s;
  transition-property: transform, opacity;
}

.hoverable:hover, .hoverable:active, .hoverable:focus{
  transform: translateY(-5px);
}
.hoverable:hover:before, .hoverable:active:before, .hoverable:focus:before{
  opacity: 1;
  transform: translateY(-5px);
}



@keyframes bounce-animation {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.bounce {
  animation-name: bounce-animation;
  animation-duration: 2s;
}



/*everything below here is just setting up the page, so dont worry about it */


@media (min-width: 768px) {
  .navbar{
    text-align: center !important;
    float: none;
    display: inline-block;
  }
}



/* Responsive Navbar */
.menu-icon {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
}

@media (max-width: 768px) {
  .ul-list {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 15vh;
    left: 0;
    z-index: 1;
    background-color: #fdeacb;
  }

  .ul-list.open {
    display: flex;
  }

  .ul-list ul {
    flex-direction: column;
  }

  .ul-list li {
    margin: 0;
  }

  .menu-icon {
    display: block;
    padding: 1em;
    align-self: center;
  }
}

@media (min-width: 768px) {
  .navbar {
    text-align: center !important;
    float: none;
    display: inline-block;
  }
}




.page-title {
  opacity: .75 !important;
}

