 

.internship-details-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #FFFFFF;
  border: 1px solid #444;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.internship-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}

.internship-image {
  flex-shrink: 0;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 8px;
}

.int-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.internship-info {
  flex-grow: 1;
}

.internship-details h1 {
  font-size: 2rem;
  color: #3f5b35;
  margin-bottom: 10px;
}

.internship-details p {
  margin-bottom: 8px;
  color: #333;
}

.internship-details strong {
  font-size: large;
  
  color: #3f5b35;
}

#apply-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

#apply-btn button {
  background-color: #59814B;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
}

#apply-btn button:hover {
  background-color: #3f5b35;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .internship-details-container {
    padding: 10px;
  }

  .internship-details {
    flex-direction: column;
    align-items: center;
  }

  .internship-info {
    text-align: center;
  }

  #apply-btn button {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .internship-details-container {
    margin: 10px;
  }

  .internship-details h1 {
    font-size: 1.5rem;
  }

  #apply-btn button {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}

.back-link {
  display: inline-block;
  margin-bottom: 10px;
  color: #59814B;
  font-weight: bolder;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}

.back-link svg {
  vertical-align: middle;
  margin-right: 5px;
}
