.profile-container {
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-title {
  margin-bottom: 20px;
  font-size: 32px;
  color: #38512F;
  font-weight: bold;
  text-align: center;
}

.profile-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 800px;
}

.profile-section {
  background-color: #38512F;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-details-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.profile-image{
  display: flex;
  justify-content: center;
}
.profile-image img {
  max-width: 150px;
  height: 160px;
  max-height: 200px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.profile-info {
  width: 100%;
}

.profile-info-table {
  width: 100%;
  border-collapse: collapse;
}

.profile-info-table td {
  padding: 10px;
  border: 1px solid #444;
}

.profile-info-table tr:nth-child(even) {
  background-color: #FFFFFF;
  color: #38512F;
}

.profile-info-table tr:hover {
  background-color: #4CAF50;
  cursor: pointer;
}

.profile-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-form-group {
  margin-bottom: 15px;
}

.profile-form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.profile-form-group input,
.profile-form-group textarea {
  padding: 8px;
  border-radius: 4px;
  border: none;
  outline: none;
  width: 100%;
  background-color: #FFFFFF;
  color: #38512F;
}

.profile-form-actions {
  display: flex;
  gap: 10px;
}

.profile-submit-button,
.profile-cancel-button,
.profile-edit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-submit-button {
  background-color: #4CAF50;
  color: #fff;
}

.profile-cancel-button {
  background-color: #ff4d4d;
  color: #fff;
}
.edit-bt-pr{
  display: flex;
  justify-content: center;
  margin: 10px;
}

.profile-edit-button {
  background-color: #4CAF50;
  color: #fff;
}

.profile-submit-button:hover,
.profile-cancel-button:hover,
.profile-edit-button:hover {
  background-color: #45a049;
}

.profile-internships-section {
  margin-top: 30px;
}

.profile-internships-table {
  width: 100%;
  border-collapse: collapse;
}

.profile-internships-table th,
.profile-internships-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.profile-internships-table th {
  background-color: #4CAF50;
  color: #fff;
}

.profile-internships-table tr:nth-child(even) {
  background-color: #FFFFFF;
  color: #38512F;
}

.profile-internships-table tr:hover {
  background-color: #45a049;
}


/* Update Payment Screenshot Section */
.profile-payment-screenshot-section {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #38512F;
  margin-top: 30px; /* Space between sections */
}

.profile-payment-screenshot-section h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
  color: #fff;
}

.payment-screenshot-form {
  display: flex;
  flex-direction: column;
}

.payment-screenshot-form .form-group {
  margin-bottom: 20px;
}

.payment-screenshot-form .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #fff;
}

.payment-screenshot-form .form-group input[type="file"] {
  display: block;
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.payment-screenshot-submit-button {
  background-color: #45a049;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-screenshot-submit-button:hover {
  background-color: #0056b3;
}
