@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

.consultancy-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.consultancy-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0;
}
.acc-head{
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #3f5b35;
  text-align: center;
}

.cons-adpt{
width: 25%;
}
.consultancy-heading {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #3f5b35;
  text-align: center;
}
.consultant-heading {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ffffff;
  text-align: center;
}
.consultancy-description {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.consultancy-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.consultancy-list-item {
  font-size: 1.2rem;
  color: #3f5b35;
  font-weight: bold;
  margin-bottom: 5px;
}

.consultancy-contact {
  color: #3f5b35;
  font-size: large;
  font-weight: bolder;
  margin-bottom: 15px;
  margin-top: 15px;
}

.consultancy-contact-list {
  color: #3f5b35;
  font-size: large;
  font-weight: bold;
}

.consultancy-main {
  display: flex;
  justify-content: space-between;
}

.consultancy-table{
  display: flex;
  flex-direction: column;
  background-color: #3f5b35;
  justify-content: space-between;
  border-radius: 30px;
  margin: 20px 0;
  width: 100%;
  padding: 20px;
}
.consultancy-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}
.consultancy-image{
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.consultancy-button {
  text-align: center;
  margin-top: 20px;
}
.consultancy-name{
  color: #ffffff;
}
.consultancy-button button {
  background-color: #59814B;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
}

.consultancy-button button:hover {
  background-color: #3f5b35;
  transform: scale(1.05);
}

.consultancy-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.consultancy-accordion {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button,
h3 {
  all: unset;
}

.consultancy-accordion-root {
  border-radius: 6px;
  width: 80%;
  background-color: var(--mauve-6);
  box-shadow: 0 2px 10px var(--black-a4);
}

.consultancy-accordion-item {
  overflow: hidden;
  margin-top: 1px;
}

.consultancy-accordion-item:first-child {
  margin-top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.consultancy-accordion-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.consultancy-accordion-item:focus-within {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 0 2px var(--mauve-12);
}

.consultancy-accordion-header {
  display: flex;
}

.consultancy-accordion-trigger {
  font-family: inherit;
  background-color: transparent;
  padding: 0 20px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1;
  color: #3f5b35;
  font-size: 1.4rem;
  box-shadow: 0 1px 0 var(--mauve-6);
  background-color: #ffffff;
}

.consultancy-accordion-trigger:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}

.consultancy-accordion-content {
  overflow: hidden;
  font-size: 17px;
  color: #333;
  background-color: #ffffff;
}

.consultancy-accordion-content[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.consultancy-accordion-content[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.consultancy-accordion-content-text {
  padding: 15px 20px;
}

.consultancy-chevron {
  color: var(--violet-10);
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.consultancy-accordion-trigger[data-state='open'] > .consultancy-chevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .consultancy-content {
    flex-direction: column;
    align-items: center;
  }

  .consultancy-card {
    width: 90%;
  }

  .consultancy-accordion {
    margin-top: 20px;
    width: 100%;
  }

  .consultancy-accordion-root {
    width: 100%;
  }

  .consultancy-cards {
    flex-direction: column;
    align-items: center;
  }
}

/* General Mobile Styles */
@media (max-width: 768px) {
  .consultancy-container {
    padding: 10px;
  }

  .cons-adpt{
    width: 100%;
  }

  .consultancy-content {
    flex-direction: column;
    align-items: center;
  }
  .consultancy-main{
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .consultancy-details {

    text-align: center;
  }

  .consultancy-table {
    width: 100%;
    padding: 10px;
  }

  .consultancy-card {
    width: 100%;
    margin: 10px 0;
  }

  .consultancy-image {
    width: 50px;
    height: 50px;
  }

  .consultancy-button button {
    width: 50%;
  }

  .consultancy-accordion {
    width: 100%;
    margin-top: 20px;
  }

  .consultancy-accordion-root {
    width: 100%;
  }
}

/* Extra Small Devices */
@media (max-width: 480px) {
  .consultancy-heading, .consultant-heading {
    font-size: 1.5rem;
  }

  .consultancy-description {
    font-size: 1rem;
  }
  .cons-adpt{
    width: 100%;
  }

  .consultancy-list-item, .consultancy-contact, .consultancy-contact-list {
    font-size: 0.9rem;
  }

  .consultancy-button button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .consultancy-image {
    width: 40px;
    height: 40px;
  }
}

#apply-bt {
  background-color: #59814B;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 20px;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
}

#apply-bt:hover {
  background-color: #3f5b35;
  transform: scale(1.05);
}
