.contactUsContainer {
    padding: 50px 20px;
}

.contactContainer {
    max-width: 1200px;
    margin: 0 auto;
}

.contactBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.contactInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 20px;
}

.contactItem {
    display: flex;
    align-items: center;
    margin: 20px;
}

.contactIcon {
    margin-right: 10px;
    color: #3f5b35;
}

.contactMap {
    margin-bottom: 20px;
}

.contact-accordion {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-bottom: 30px;
}

.contact-accordion-header {
    font-size: 1.5rem;
    color: #3f5b35;
    font-weight: bolder;
    margin-bottom: 20px;
}

span{
    color: #333;
    font-weight: bold;
}
.contact-accordion-root {
    border-radius: 6px;
    width: 80%;
    background-color: var(--mauve-6);
    box-shadow: 0 2px 10px var(--black-a4);
}

.contact-accordion-item {
    overflow: hidden;
    margin-top: 1px;
}

.contact-accordion-item:first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.contact-accordion-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.contact-accordion-item:focus-within {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0 2px var(--mauve-12);
}

.contact-accordion-trigger {
    font-family: inherit;
    background-color: transparent;
    padding: 0 20px;
    height: 45px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    line-height: 1;
    color: #3f5b35;
    font-weight: bold;
    box-shadow: 0 1px 0 var(--mauve-6);
    background-color: #FFFFFF;
}

.contact-accordion-trigger:hover {
    cursor: pointer;
    background-color: #e7e6e6;
}

.contact-accordion-content {
    overflow: hidden;
    font-size: 15px;
    color:#333;
    background-color: #FFFFFF;
}

.contact-accordion-content[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.contact-accordion-content[data-state='closed'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.contact-accordion-content-text {
    padding: 15px 20px;
}

.contact-chevron {
    color: var(--violet-10);
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.contact-accordion-trigger[data-state='open'] > .contact-chevron {
    transform: rotate(180deg);
}

.messageBox{
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.contactForm{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.contactInput{
    width: 100%;
    padding: 10px;
    margin: 10px;
    border-radius: 6px;
    border: 1px solid var(--mauve-6);
}

.applyButton{
    padding: 10px;
    align-self: center;
    margin: 10px;
    border-radius: 6px;
    border: 1px solid var(--mauve-6);
    background-color: #59814B;
    text-align: center;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.2s, background 0.2s;
}

.applyButton:hover{
    background-color: #3f5b35;
    transform: scale(1.05);
}

.contactTextArea{
    width: 100%;
    padding: 10px;
    margin: 10px;
    border-radius: 6px;
    border: 1px solid var(--mauve-6);
}

@media (max-width: 1024px) {
    .contactContainer {
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .contactBox {
        flex-direction: column;
        align-items: flex-start;
    }

    .contactInfo {
        margin-bottom: 10px;
    }

    .contactMap iframe {
        width: 100%;
        height: 250px; /* Adjust height for smaller screens */
    }

    .contact-accordion {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .contact-accordion-root {
        width: 100%;
    }

    .contact-accordion-header {
        font-size: 1.2rem;
    }

    .messageBox {
        width: 90%;
    }

    .contactInput,
    .contactTextArea {
        width: calc(100% - 20px);
    }
}
